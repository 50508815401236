import { render, staticRenderFns } from "./BreadcrumbUpload.vue?vue&type=template&id=7b784330&"
import script from "./BreadcrumbUpload.vue?vue&type=script&lang=js&"
export * from "./BreadcrumbUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CStack: require('@chakra-ui/vue').CStack, CBreadcrumbLink: require('@chakra-ui/vue').CBreadcrumbLink, CBreadcrumbItem: require('@chakra-ui/vue').CBreadcrumbItem, CBreadcrumb: require('@chakra-ui/vue').CBreadcrumb})
