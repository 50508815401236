<template>
  <CBreadcrumb separator=">" color="breadcrumbNotActive2" :spacing="5" pt="10px" maxW="1080px" mx="auto">
    <CBreadcrumbItem @click.native="$router.push({ name: 'Choose Web CV Wizard' })">
      <CBreadcrumbLink>
        <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
          <CBox d="table-cell" verticalAlign="middle" h="inherit">
            <CImage :src="require('@/assets/icon/ic-breadcrumb-webcv-blue.svg')" w="18px" />
          </CBox>
          <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="5px">
            <CText color="activeMenu" fontWeight="bold">Pilih Desain</CText>
          </CBox>
        </CStack>
      </CBreadcrumbLink>
    </CBreadcrumbItem>
    <CBreadcrumbItem @click.native="$router.push({ name: 'Dashboard Desain CV' })">
      <CBreadcrumbLink>
        <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
          <CBox d="table-cell" verticalAlign="middle" h="inherit">
            <CImage v-if="order == 2 || order == 3 || order == 4 || order == 5 || order == 6" :src="require('@/assets/icon/breadcrumb-user-blue.png')" w="18px" />
            <CImage v-else :src="require('@/assets/icon/breadcrumb-user.svg')" w="18px" />
          </CBox>
          <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="5px">
            <CText v-if="order == 2 || order == 3 || order == 4 || order == 5 || order == 6" color="activeMenu" fontWeight="bold">Profil Web CV</CText>
            <CText v-else color="breadcrumbNotActive2" fontWeight="bold">Profil Web CV</CText>
          </CBox>
        </CStack>
      </CBreadcrumbLink>
    </CBreadcrumbItem>
    <CBreadcrumbItem @click.native="$router.push({ name: 'Dashboard Upload Photo' })">
      <CBreadcrumbLink>
        <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
          <CBox d="table-cell" verticalAlign="middle" h="inherit">
            <CImage v-if="order == 3 || order == 4 || order == 5 || order == 6" :src="require('@/assets/icon/image-circle-blue.svg')" w="18px" />
            <CImage v-else :src="require('@/assets/icon/image-circle.svg')" w="18px" />
          </CBox>
          <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="5px">
            <CText v-if=" order == 3 || order == 4 || order == 5 || order == 6" color="activeMenu" fontWeight="bold">Foto Web CV</CText>
            <CText v-else color="breadcrumbNotActive2" fontWeight="bold">Foto Web CV</CText>
          </CBox>
        </CStack>
      </CBreadcrumbLink>
    </CBreadcrumbItem>
    <CBreadcrumbItem @click.native="$router.push({ name: 'Video CV' })">
      <CBreadcrumbLink>
        <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
          <CBox d="table-cell" verticalAlign="middle" h="inherit">
            <CImage v-if="order == 4 || order == 5 || order == 6" :src="require('@/assets/icon/player-circle-blue.png')" w="18px" />
            <CImage v-else :src="require('@/assets/icon/player-circle-white.png')" w="18px" />
          </CBox>
          <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="5px">
            <CText v-if="order == 4 || order == 5 || order == 6" color="activeMenu" fontWeight="bold">Video CV</CText>
            <CText v-else color="breadcrumbNotActive2" fontWeight="bold">Video CV</CText>
          </CBox>
        </CStack>
      </CBreadcrumbLink>
    </CBreadcrumbItem>
    <CBreadcrumbItem @click.native="$router.push({ name: 'Config Services' })">
      <CBreadcrumbLink>
        <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
          <CBox d="table-cell" verticalAlign="middle" h="inherit">
            <CImage v-if="order == 5 || order == 6" :src="require('@/assets/icon/icon-service-circle-blue.png')" w="18px" />
            <CImage v-else :src="require('@/assets/icon/icon-service-circle-white.png')" w="18px" />
          </CBox>
          <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="5px">
            <CText v-if="order == 5 || order == 6" color="activeMenu" fontWeight="bold">Keahlian</CText>
            <CText v-else color="breadcrumbNotActive2" fontWeight="bold">Keahlian</CText>
          </CBox>
        </CStack>
      </CBreadcrumbLink>
    </CBreadcrumbItem>
    <CBreadcrumbItem @click.native="$router.push({ name: 'Config Complete' })">
      <CBreadcrumbLink>
        <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
          <CBox d="table-cell" verticalAlign="middle" h="inherit">
            <CImage v-if="order == 6" :src="require('@/assets/icon/check-circle-blue.png')" w="18px" />
            <CImage v-else :src="require('@/assets/icon/check-circle-white.png')" w="18px" />
          </CBox>
          <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="5px">
            <CText v-if="order == 6" color="activeMenu" fontWeight="bold">Complete</CText>
            <CText v-else color="breadcrumbNotActive2" fontWeight="bold">Complete</CText>
          </CBox>
        </CStack>
      </CBreadcrumbLink>
    </CBreadcrumbItem>
  </CBreadcrumb>
</template>

<script>
export default {
  computed: {
    order() {
      let order = 1
      const page2 = ["Dashboard Desain CV", "Dashboard Upload Photo", "Video CV", "Config Services", "Config Complete"];
      const page3 = ["Dashboard Upload Photo", "Video CV", "Config Services", "Config Complete"];
      const page4 = ["Video CV", "Config Services", "Config Complete"];
      const page5 = ["Config Services", "Config Complete"];
      const page6 = ["Config Complete"];
      if (page2.includes(this.$route.name)) order = 2
      if (page3.includes(this.$route.name)) order = 3
      if (page4.includes(this.$route.name)) order = 4
      if (page5.includes(this.$route.name)) order = 5
      if (page6.includes(this.$route.name)) order = 6
      return order;
    }
  }
}
</script>
